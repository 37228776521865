const Mustache = require('mustache');

let catalog = {};

module.exports.init = (dictionary) => {
  catalog = dictionary;
}

module.exports.__ = (key, map = {}) => {
  return catalog[key] ? Mustache.render(catalog[key], map) : key;
}